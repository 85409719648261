import React, { Component } from "react";
import Card from "../OC/card";

import "@styles/main.scss";

const meet = () => {
  return (
    <main>
      <h1 className="display"> Organizing Committee </h1>
      <Card/>
    </main>
  );
};
export default meet;
