const images = [
  {
    src: "images/2022/organizers-webp/CaseIT-OC-simon-flip.webp",
    name: "Simon Ford",
    position: "Academic Director",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-hansel.webp",
    name: "Hansel Wu",
    position: "Student Engagement and Development Office (SEDO) Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-emily.webp",
    name: "Emily Kim",
    position: "Chair",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-rachel.webp",
    name: "Rachel Dee",
    position: "Vice Chair of Competition Execution",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-rachelv.webp",
    name: "Rachel Vicencio",
    position: "Vice Chair of Marketing",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-megan.webp",
    name: "Megan Wong",
    position: "Director of Logistics",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-lizzy.webp",
    name: "Elizabeth Teefel",
    position: "Director of Logistics",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-wayne.webp",
    name: "Wayne Yan Muk",
    position: "Director of Events",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-jodi.webp",
    name: "Jodi Tabuchi",
    position: "Director of Competitor Experience",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-henry.webp",
    name: "Henry Ngyuen",
    position: "Director of Technology",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-michelle.webp",
    name: "Michelle Kang",
    position: "Director of Finance",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-ali.webp",
    name: "Ali Leung",
    position: "Director of University Relations",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-russell.webp",
    name: "Russell Kwok",
    position: "Director of Corporate Relations",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-tejas.webp",
    name: "Tejas Gandesha",
    position: "Director of Corporate Relations",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-bita.webp",
    name: "Bita Zojaji",
    position: "Director of Internal Strategy",
    team: "",
    alt: "Organizing Committee Image",
  },
  // {
  //   src: "images/2022/organizers-webp/CaseIT-OC-daniel.webp",
  //   name: "Test",
  //   position: "position",
  //   team: "",
  // alt: "Organizing Committee Image"
  // },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-maya.webp",
    name: "Maya Ramadhina",
    position: "Director of Design",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-andrea.webp",
    name: "Andrea Chan",
    position: "Director of Content Strategy",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-russellee.webp",
    name: "Russellee Santos",
    position: "Logistics Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-simrit.webp",
    name: "Simrit Dhaliwal",
    position: "Events Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-braden.webp",
    name: "Braden Lo",
    position: "Competitor Experience Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-johnson.webp",
    name: "Johnson Ngo",
    position: "Technology Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-paul.webp",
    name: "Paul Lee",
    position: "Corporate Relations Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-bryan.webp",
    name: "Bryan Li",
    position: "Media Associate",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-neva.webp",
    name: "Neva Hoang",
    position: "Design Associate",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-james.webp",
    name: "James Duong",
    position: "Design Associate",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-vanessa.webp",
    name: "Vanessa Chau",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-brandonlau.webp",
    name: "Brandon Lau",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-liz.webp",
    name: "Liz Gilder",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-kristy.webp",
    name: "Kristy Hong",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-lawrence.webp",
    name: "Lawrence Zhu",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-jacky.webp",
    name: "Jacky Chen",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-selena.webp",
    name: "Selena Situ",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-brandonxu.webp",
    name: "Brandon Xu",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-jarl.webp",
    name: "Jarl Arciaga",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-mihee.webp",
    name: "Mi Hee Hashimoto Chang",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-yeelong.webp",
    name: "Yee Loong Tang",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-kenneth.webp",
    name: "Kenneth Jiang",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-ryan.webp",
    name: "Ryan Maclean",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-christina.webp",
    name: "Christina Cao",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-caeleigh.webp",
    name: "Caeleigh Cardwell",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-aliya.webp",
    name: "Aliya Hussein",
    position: "Team Host",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-emilysu.webp",
    name: "Emily Su",
    position: "PIVOT Chair",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-douglas.webp",
    name: "Douglas Cheung",
    position: "PIVOT Director of External Relations",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-melissa.webp",
    name: "Melissa Bouwmeester",
    position: "PIVOT Director of Marketing",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-justin.webp",
    name: "Justin Jung",
    position: "PIVOT Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
  {
    src: "images/2022/organizers-webp/CaseIT-OC-esther.webp",
    name: "Esther Ng",
    position: "PIVOT Coordinator",
    team: "",
    alt: "Organizing Committee Image",
  },
]

export default images
